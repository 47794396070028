import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import HomePage from "./Pages/HomePage";
import Product from "./Pages/Product";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import News from "./Pages/News";
import Docs from "./Pages/Docs";
import ProductDetailPage from './Pages/ProductDetailPage';

import NotFound from './Pages/NotFound';
import Header from "./components/header";
import Footer from "./components/footer";
import FistDIV from "./components/fistdiv";


const main = () => {
  return (
    <>  
        <Router>
          <Header />
          <FistDIV />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="Product" element={<Product />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="About" element={<About />} />
            <Route path="News" element={<News />} />
            <Route path="Docs" element={<Docs />} />
            <Route path="/Product/:id" element={<ProductDetailPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
    </>
  )
}

export default main
