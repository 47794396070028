import React from "react";
import "../Style/Style.css";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      <div className="not-found container">
        <h1>404</h1>
        <h2>Trang không tồn tại</h2>
        <p>
          Xin lỗi, trang bạn tìm không tồn tại, hãy quay lại với trang chủ của
          chúng tôi, hoặc xem các sản phẩm nổi bật bên dưới
        </p>
        <Link to="/">
          Trang Chủ<i class="fa-solid fa-arrow-rotate-left"></i>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
