import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./style/slider.css";

const slider = () => {
  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <OwlCarousel className="owl-theme" {...options}>
      <div class="items slideritem_1"></div>
      <div class="items slideritem_2"></div>
      <div class="items slideritem_3"></div>
      <div class="items slideritem_4"></div>
    </OwlCarousel>
  );
};

export default slider;
