export const ListSPCuaTD = [
    {
      id: 1,
      type: "Âm Sàn",
      title: "MOTOR CỔNG ÂM SÀN TỰ ĐỘNG RUSTER 24V – 600KG/CÁNH",
      avatar: "../img/motor-am-san-ruster-24v.webp",
      oldPri: "40.000.000đ",
      pri: "38.900.000₫",
      license: "Ruster",
      model: "20QR303",
      madein: "Việt Nam",
      describe: "Motor cổng âm sàn tự động Ruster 24V – 600kg/cánh là thiết bị dùng để đóng/mở cổng tự động một cách êm ái, an toàn và tiện lợi",
      highlightImages: [
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp"
        ],
      hot: true
    },
    {
      id: 2,
      type: "Âm Sàn",
      title: "JOTOR CỔNG ÂM SÀN TỰ ĐỘNG RUSTER 24V – 600KG/CÁNH",
      avatar: "../img/motor-am-san-ruster-24v.webp",
      oldPri: "40.000.000đ",
      pri: "38.900.000₫",
      license: "Ruster",
      model: "20QR303",
      madein: "Việt Nam",
      describe: "Motor cổng âm sàn tự động Ruster 24V – 600kg/cánh là thiết bị dùng để đóng/mở cổng tự động một cách êm ái, an toàn và tiện lợi",
      highlightImages: [
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp"
        ],
      hot: false
    },
    {
      id: 3,
      type: "Âm Sàn",
      title: "IOTOR CỔNG ÂM SÀN TỰ ĐỘNG RUSTER 24V – 600KG/CÁNH",
      avatar: "../img/motor-am-san-ruster-24v.webp",
      oldPri: "40.000.000đ",
      pri: "38.900.000₫",
      license: "Ruster",
      model: "20QR303",
      madein: "Việt Nam",
      describe: "Motor cổng âm sàn tự động Ruster 24V – 600kg/cánh là thiết bị dùng để đóng/mở cổng tự động một cách êm ái, an toàn và tiện lợi",
      highlightImages: [
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp"
        ],
      hot: true
    },
    {
      id: 4,
      type: "Âm Sàn",
      title: "HOTOR CỔNG ÂM SÀN TỰ ĐỘNG RUSTER 24V – 600KG/CÁNH",
      avatar: "../img/motor-am-san-ruster-24v.webp",
      oldPri: "40.000.000đ",
      pri: "38.900.000₫",
      license: "Ruster",
      model: "20QR303",
      madein: "Việt Nam",
      describe: "Motor cổng âm sàn tự động Ruster 24V – 600kg/cánh là thiết bị dùng để đóng/mở cổng tự động một cách êm ái, an toàn và tiện lợi",
      highlightImages: [
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp"
        ],
      hot: true
    },
    {
      id: 5,
      type: "Âm Sàn",
      title: "HOTOR CỔNG ÂM SÀN TỰ ĐỘNG RUSTER 24V – 600KG/CÁNH",
      avatar: "../img/motor-am-san-ruster-24v.webp",
      oldPri: "40.000.000đ",
      pri: "38.900.000₫",
      license: "Ruster",
      model: "20QR303",
      madein: "Việt Nam",
      describe: "Motor cổng âm sàn tự động Ruster 24V – 600kg/cánh là thiết bị dùng để đóng/mở cổng tự động một cách êm ái, an toàn và tiện lợi",
      highlightImages: [
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp",
            "../img/motor-am-san-ruster-24v.webp"
        ],
      hot: true
    }
  ]