import React from "react";
import NewPage from "../components/NewPage/NewpageCP";

const About = () => {
  return (
    <div>
      Đây là pages Tin mới
      <NewPage />
    </div>
  );
};

export default About;
