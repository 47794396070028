import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ListSPCuaTD } from "../Data/ListSPCuaTD";
import '../Style/ProductDetailPage.css'
import Hotproducts from "../components/HotProducts/HotproductsCp";
import NotFound from "./NotFound";
import LinkList from "../components/Linklist/LinklistCP";
import ProductDetail from "../components/DetailProduct/DetailProduc";

const ProductDetailPage = () => {
  const { id } = useParams();
  const product = ListSPCuaTD.find((p) => p.id === parseInt(id));
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (
      product &&
      product.highlightImages &&
      product.highlightImages.length > 0
    ) {
      setSelectedImage(product.highlightImages[0]);
    }
  }, [product]);

  if (!product) return null;

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  if (!product) {
    return (
      <div>
        <NotFound />
        <Hotproducts className="container"></Hotproducts>
      </div>
    );
  }

  return (
    <div className="product-detail-page container">
      <LinkList />
      <h1>{product.title}</h1>
      <div className="modal-content-detail-main">
        <div className="Image_left">
          <img
            src={selectedImage}
            zoomSrc={selectedImage}
            alt={product.title}
          />
          <div className="highlight-images1">
            {product.highlightImages &&
              product.highlightImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`${product.title} highlight ${index}`}
                  className="highlight-img"
                  onClick={() => handleImageClick(img)}
                />
              ))}
          </div>
        </div>
        <div className="modal-content-right">
          <p>{product.oldPri}</p>
          <p>{product.pri}</p>
          <p>Hãng sản xuất: {product.license}</p>
          <p>Mẫu: {product.model}</p>
          <p>Sản xuất tại: {product.madein}</p>
          <p>Mô tả: {product.describe}</p>
          <div className="bt-a-SP">
            <Link to={""}>
              Nhận báo giá &nbsp;
              <i class="fa-solid fa-arrow-right-to-bracket"></i>
            </Link>
          </div>
          <div className="bt-bottom">
            <div>
              <Link to={""}>Tư vấn ngay</Link>
            </div>
            <div>
              <Link to={""}>Tài liệu</Link>
            </div>
          </div>
        </div>
      </div>
      <ProductDetail />
    </div>
  );
};

export default ProductDetailPage;
