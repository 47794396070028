import "./style/style.css";
import logo from "../img/logoruster.png";
import SearchBar from "./SearchBar/SeachBar";
import { Link } from "react-router-dom";

const TopHeader = () => {
  return (
    <div className="Header-container">
      <div className="Header-content">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="Danhmucsanpham">
        <ul>
          <li>
            <Link to="/Products" className="Item-1"><i class="fa-solid fa-bars"></i>&nbsp;Danh mục sản phẩm và dịch vụ</Link>
            <ul>
              <li>
                <a href="">Cửa Tự động</a>
              </li>
              <li>
                <a href="">Thiêt bị Camera</a>
              </li>
              <li>
                <a href="">Linh kiện điện tử</a>
              </li>
              <li>
                <a href="">Tư vấn lắp đặt camera</a>
              </li>
              <li>
                <a href="">Tư vấn Lắp đặt Hạ Tâng CNTT</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <SearchBar></SearchBar>
    </div>
  );
};

const Nar = () => {
  return (
    <div className="Nar-fullwith">
      <div className="container nartop">
        <div className="nar-contact">
          <div className="Header-content">
            <div className="Icon-header-content">
              <i class="fa-solid fa-envelope"></i>
            </div>
            <p>Solexvietnam@gmail.com</p>
          </div>
          <div className="Header-content">
            <div className="Icon-header-content">
              <i class="fa-solid fa-headset"></i>
            </div>
            <p>Solexvietnam@gmail.com</p>
          </div>
        </div>
        <div className="Nar-container">
          <Link to="/About">Về chúng tôi</Link>
          <Link to="/Contact">Liên hệ</Link>
          <Link to="/News">Tin Tức</Link>
          <Link to="/Docs">Tài Liệu</Link>
        </div>
      </div>
    </div>
  );
};

const header = () => {
  return (
    <div>
      <div className="Head-container-main">
        <Nar />
        <TopHeader />
        <div className="Main-container-main"></div>
      </div>
    </div>
  );
};

export default header;
