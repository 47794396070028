import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ListSPCuaTD } from "../../Data/ListSPCuaTD"; // Đảm bảo đường dẫn chính xác tới file data
import "./style.css";

// Đối tượng ánh xạ các đường dẫn tới tên hiển thị
const pathMappings = {
  "": "Trang chủ",
  Product: "Sản phẩm",
  // Bạn có thể thêm các ánh xạ khác ở đây nếu cần
};

// Hàm lấy tên sản phẩm từ ID
const getProductNameById = (id) => {
  const product = ListSPCuaTD.find((product) => product.id.toString() === id);
  return product ? product.title : id;
};

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="breadcrumb">
      <Link to="/">
        <i class="fa-solid fa-house"></i>Trang chủ
      </Link>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const displayName = pathMappings[value] || getProductNameById(value);

        return (
          <span key={to}>
            {" > "}
            <Link to={to}>{displayName}</Link>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
