import React, { useState } from 'react';

const ArticlePage = () => {
    const [articles, setArticles] = useState([]);
    const [newArticle, setNewArticle] = useState('');

    const handleAddArticle = () => {
        if (newArticle.trim()) {
            setArticles([...articles, newArticle]);
            setNewArticle('');
        }
    };

    return (
        <div>
            <h1>Danh Sách Bài Viết</h1>
            <ul>
                {articles.map((article, index) => (
                    <li key={index}>{article}</li>
                ))}
            </ul>
            <div>
                <input
                    type="text"
                    value={newArticle}
                    onChange={(e) => setNewArticle(e.target.value)}
                    placeholder="Nhập bài viết mới"
                />
                <button onClick={handleAddArticle}>Thêm Bài Viết</button>
            </div>
        </div>
    );
};

export default ArticlePage;