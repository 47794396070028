import React from "react";
import MainHeader from "../components/MainHeaderListmenu";

const HomePage = () => {
  return (
    <div>
      <MainHeader></MainHeader>
    </div>
  );
};

export default HomePage;
