import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { ListSPCuaTD } from '../../Data/ListSPCuaTD';
import '../CompListSP/ListSanpham.css';
import '../../components/style/style.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

const DSsanphamCuaTD = ({ product, onViewDetails }) => {
    return (
        <div className='ProductMain'>
            <img src={product.avatar} alt={product.title} />
            <h3>{product.title}</h3>
            <h4 className='oldPri'>{product.oldPri}</h4>
            <h4 className='Pri'>{product.pri}</h4>
            <div className='bt-a-SP'>
                <button onClick={() => onViewDetails(product)}>Xem chi tiết</button>
                <a href="/">Báo giá ngay</a>
            </div>
        </div>
    );
};

const ProductDetailModal = ({ product, onClose, onViewProduct }) => {
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        if (product && product.highlightImages && product.highlightImages.length > 0) {
            setSelectedImage(product.highlightImages[0]);
        }
    }, [product]);

    if (!product) return null;

    const handleImageClick = (img) => {
        setSelectedImage(img);
    };

    
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>X</button>
                <h2>{product.title}</h2>
                <div className='modal-content-main'>
                    <div className="highlight-images">
                        {product.highlightImages && product.highlightImages.map((img, index) => (
                            <img 
                                key={index} 
                                src={img} 
                                alt={`${product.title} highlight ${index}`} 
                                className="highlight-img" 
                                onClick={() => handleImageClick(img)} 
                            />
                        ))}
                    </div>
                    <img src={selectedImage} zoomSrc={selectedImage} alt={product.title} />
                    <div className='modal-content-right'>
                        <p>{product.oldPri}</p>
                        <p>{product.pri}</p>
                        <p>Hãng sản xuất: {product.license}</p>
                        <p>Mẫu: {product.model}</p>
                        <p>Sản xuất tại: {product.madein}</p>
                        <p>Mô tả: {product.describe}</p>       
                        <div className="bt-a-SP">
                            <Link to={`/product/${product.id}`}>Xem thêm chi tiết &nbsp;
                            <i class="fa-solid fa-arrow-right-to-bracket"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ListSanpham = () => {
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleViewDetails = (product) => {
        setSelectedProduct(product);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    const handleSearch = (term) => {
        setSearchTerm(term.toLowerCase());
    };

    const handleViewProduct = (productId) => {
        setSelectedProductId(productId);
      
    };

    const filteredProducts = ListSPCuaTD.filter(item =>
        item.title.toLowerCase().includes(searchTerm)
    );

    const options  = {
        items:1,
        loop:false,
        nav:false,
        dots:true,
        margin:10,
        autoplay:false,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:4
            }
        }
    };

    return (
        <div className='content-listSP container'>

            <h2 className="Title">Sản phẩm nổi bật &nbsp; <i className="fa-solid fa-caret-right"></i></h2>
            <OwlCarousel {...options}>
                {ListSPCuaTD.filter(item => item.hot).map((item) => (
                    <DSsanphamCuaTD
                        key={item.id}
                        product={item}
                        onViewDetails={handleViewDetails}
                    />
                ))}
            </OwlCarousel>
            <ProductDetailModal product={selectedProduct} onClose={handleCloseModal} onViewProduct={handleViewProduct} />
        </div>
    );
};

export default ListSanpham;
