import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { ListSPCuaTD } from '../../Data/ListSPCuaTD';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false); // State để điều khiển hiển thị gợi ý
  const navigate = useNavigate();
  const searchResultsRef = useRef(null); // Ref để tham chiếu đến phần search-results

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    setQuery(keyword);

    if (keyword.length > 0) {
      const filteredResults = ListSPCuaTD.filter(item =>
        item.title.toLowerCase().includes(keyword)
      );
      setResults(filteredResults);
      setShowSuggestions(true); // Hiển thị gợi ý khi có từ khóa tìm kiếm
    } else {
      const hotProducts = ListSPCuaTD.filter(item => item.hot);
      setResults(hotProducts);
      setShowSuggestions(true); // Hiển thị gợi ý khi từ khóa tìm kiếm rỗng
    }
  };

  const handleResultClick = (id) => {
    navigate(`/product/${id}`);
    setShowSuggestions(false); // Đóng gợi ý khi click vào kết quả tìm kiếm
  };

  const handleClear = () => {
    setQuery('');
    setResults([]); // Xóa kết quả khi clear
  };

  return (
    <div className='search-container'>
      <div className='search-input-container'>
        <input
          type='text'
          placeholder='Tìm kiếm sản phẩm...'
          value={query}
          onChange={handleSearch}
          className='search-input'
        />
        {query.length > 0 && (
          <button className='clear-button' onClick={handleClear}>
            X
          </button>
        )}
      </div>
      {showSuggestions && (
        <div className='search-results' ref={searchResultsRef}>
          {results.map(result => (
            <div
              key={result.id}
              className='search-result-item'
              onClick={() => handleResultClick(result.id)}
            >
              <img src={result.avatar} alt={result.title} className='search-result-avatar' />
              <div>
                <p>{result.title}</p>
                <p>{result.pri}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
