import React from 'react'
import './style/style.css'

const Fistdiv = () => {
  return (
    <div className='fistdiv'>
    </div>
  )
}

export default Fistdiv
