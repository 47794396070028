import React from "react";
import { useParams } from "react-router-dom";
import { ListSPCuaTD } from "../../Data/ListSPCuaTD"; // Đảm bảo đường dẫn chính xác tới file data
import "./style.css";

const ProductDetail = () => {
  const { id } = useParams();
  const product = ListSPCuaTD.find((product) => product.id.toString() === id);

  return (
    <div className="product-detail">
      <h3>THÔNG TIN CHI TIẾT SẢN PHẨM</h3>
      <p>{product.describe}</p>
      <img src={product.avatar} alt={product.title} className="product-image" />

      <h2>I. Thông số kỹ thuật {product.title}</h2>
      <img
        src="../img/thong-so-ki-thuat-motor-am-san-ruster-24v.webp"
        alt="Thông số kỹ thuật"
      />

      <h2>II. Thiết bị đi kèm motor cổng âm sàn tự động Ruster 24V</h2>
      <img
        src="../img/thiet-bi-di-kem-motor-am-san-ruster-24v.webp"
        alt="Thiết bị đi kèm"
      />

      <h2>III. Ưu điểm của motor cổng âm sàn tự động Ruster 24V</h2>
      <h3>1. Hoạt động mạnh mẽ và êm ái</h3>
      <ul>
        <li>
          <strong>Lực đẩy lớn:</strong> Motor có thể đẩy cánh cổng nặng tới
          600kg mỗi cánh, phù hợp với các cổng có kích thước lớn và nặng.
        </li>
        <li>
          <strong>Hoạt động êm ái:</strong> Nhờ sử dụng cơ cấu truyền động âm
          sàn hiện đại, motor vận hành êm ái, không gây tiếng ồn, mang lại sự
          sang trọng và đẳng cấp cho ngôi nhà của bạn.
        </li>
        <li>
          <strong>Tốc độ mở/đóng nhanh:</strong> Cổng có thể mở/đóng với tốc độ
          lên đến 15 giây mỗi cánh, tiết kiệm thời gian cho bạn.
        </li>
      </ul>

      <h3>2. An toàn và tin cậy</h3>
      <ul>
        <li>
          <strong>Hệ thống an toàn:</strong> Motor được trang bị nhiều tính năng
          an toàn như cảm biến chống va đập, tự động đảo chiều khi gặp chướng
          ngại vật. Đặc biệt, khi mất điện motor sẽ tự động khóa cơ để đảm bảo
          cổng không bị mở ra ngoài ý muốn.
        </li>
        <li>
          <strong>Chất liệu cao cấp:</strong> Vỏ motor được làm từ nhôm đúc
          nguyên khối, chống thấm nước tốt, hoạt động bền bỉ trong mọi điều kiện
          thời tiết.
        </li>
        <li>
          <strong>Hoạt động ổn định:</strong> Motor sử dụng điện áp 24V an toàn,
          hoạt động ổn định và ít xảy ra sự cố.
        </li>
      </ul>

      <h3>3. Tiện lợi và thông minh</h3>
      <ul>
        <li>
          <strong>Điều khiển từ xa:</strong> Cổng có thể điều khiển từ xa bằng
          remote, giúp bạn dễ dàng đóng/mở cổng mà không cần phải đến tận nơi.
        </li>
        <li>
          <strong>Kết nối với hệ thống nhà thông minh:</strong> Motor có thể kết
          nối với hệ thống nhà thông minh, cho phép bạn điều khiển cổng bằng
          điện thoại thông minh hoặc các thiết bị thông minh khác.
        </li>
        <li>
          <strong>Lưu trữ dữ liệu đóng/mở cổng:</strong> Motor có thể lưu trữ dữ
          liệu đóng/mở cổng trong vòng 30 ngày, giúp bạn theo dõi lịch sử hoạt
          động của cổng.
        </li>
      </ul>

      <h3>4. Thẩm mỹ cao</h3>
      <ul>
        <li>
          <strong>Thiết kế hiện đại:</strong> Motor được thiết kế âm sàn, giúp
          mang lại sự sang trọng và thẩm mỹ cho ngôi nhà của bạn.
        </li>
        <li>
          <strong>Màu sắc trang nhã:</strong> Motor có màu sắc trang nhã, phù
          hợp với mọi kiến trúc nhà ở.
        </li>
      </ul>

      <h3>5. Dễ dàng lắp đặt và sử dụng</h3>
      <ul>
        <li>
          <strong>Cấu tạo đơn giản:</strong> Motor có cấu tạo đơn giản, dễ dàng
          lắp đặt và sử dụng.
        </li>
        <li>
          <strong>Bảo hành chính hãng:</strong> Motor được bảo hành chính hãng
          24 tháng, đảm bảo chất lượng và dịch vụ sau bán hàng tốt.
        </li>
      </ul>

      <h2>IV. Lưu ý khi lắp đặt motor cổng âm sàn tự động Ruster 24V</h2>
      <ul>
        <li>
          <strong>Lắp đặt:</strong> Việc lắp đặt motor cần được thực hiện bởi kỹ
          thuật viên có chuyên môn và kinh nghiệm. Cần tuân thủ các hướng dẫn
          lắp đặt của nhà sản xuất. Đảm bảo motor được lắp đặt đúng vị trí, chắc
          chắn và cân bằng.
        </li>
        <li>
          <strong>Sử dụng:</strong> Không sử dụng motor để mở/đóng cổng khi có
          người hoặc vật cản đang di chuyển trong khu vực đóng/mở cổng. Không
          dùng tay đẩy hoặc kéo cánh cổng khi motor đang hoạt động. Thường xuyên
          kiểm tra và bảo dưỡng motor theo hướng dẫn của nhà sản xuất. Vệ sinh
          motor định kỳ để loại bỏ bụi bẩn và tạp chất. Sử dụng nguồn điện phù
          hợp với motor. Không tự ý sửa chữa motor khi gặp sự cố. Hãy liên hệ
          với Cổng tự động Ruster để được hỗ trợ.
        </li>
        <li>
          <strong>Motor cổng âm sàn tự động Ruster 24V</strong> được thiết kế để
          sử dụng cho các loại cổng có trọng lượng tối đa 600kg/cánh. Motor có
          độ ồn thấp, không ảnh hưởng đến môi trường xung quanh. Motor được
          trang bị hệ thống an toàn tự động dừng khi gặp chướng ngại vật. Bằng
          cách tuân thủ các lưu ý trên, bạn có thể đảm bảo motor hoạt động an
          toàn, hiệu quả và bền bỉ.
        </li>
      </ul>

      <h2>V. Hình ảnh công trình motor cổng âm sàn tự động Ruster 24V</h2>
      <div className="Detail-img">
        <img
          src="../img/cong-tu-dong-ruster.jpg"
          alt="Motor cổng âm sàn Ruster 24V"
        />
        <img
          src="../img/cong-tu-dong-ruster.jpg"
          alt="Motor âm sàn Ruster 24V"
        />
      </div>

      <h2>VI. Địa chỉ phân phối và lắp đặt cổng tự động số 1 Việt Nam</h2>
      <p>
        Bên cạnh việc lựa chọn motor cổng tự động phù hợp với công trình về
        thiết kế và môi trường lắp đặt thì việc lựa chọn đơn vị phân phối và lắp
        đặt motor cổng thông minh CHẤT LƯỢNG – UY TÍN là bài toán đau đầu của
        chủ đầu tư/ quý khách hàng khi lắp đặt.
      </p>
      <p>
        Giữa hàng ngàn thương hiệu cổng và đơn vị phân phối trên thị trường,
        Ruster Việt Nam tự hào là thương hiệu motor cổng tự động được sản xuất
        trực tiếp tại Việt Nam dựa trên công nghệ tiên tiến Châu Âu.
      </p>
      <p>
        Ruster Việt Nam là nhà phân phối độc quyền cho các thương hiệu cổng tự
        động chính hãng VDS, Kinggates v.v. không thông qua bên thứ 3. Với nhiều
        năm kinh nghiệm trong lĩnh vực này, chúng tôi luôn hướng tới những sản
        phẩm có chất lượng tốt nhất.
      </p>
      <div className="info">
        <p>
          <strong>Công Ty Cổ Phần Solex Việt Nam – Ruster Việt Nam</strong>
        </p>
        <p>Địa chỉ: 136B Phan Đình Phùng, TP.Thái Nguyên</p>
        <p>Điện thoại: 0869.678.688</p>
        <p>
          Trang web:{" "}
          <a
            href="https://www.hoangduong.vn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.hoangduong.vn/
          </a>
        </p>
      </div>
    </div>
  );
};

export default ProductDetail;
