import "./style/style.css";
import logo from "../img/logoruster.png";
import { Danhmucmenu } from "../Data/Danhmucmenu";

const ListMenu = (props) => {
  return (
    <div>
      <a href={props.link}>{props.title}</a>
    </div>
  );
};

const footer = () => {
  return (
    <div>
      <div className="Footer-container">
        <div className="container">
          <div className="top-foot-content">
            <img src={logo} alt="Logo" className="logo-footer" />;
            <div className="icon-social pad-bottom">
              <a href="#" className="item">
                <i class="fa-brands fa-youtube"></i>
              </a>
              <a href="#" className="item">
                <i class="fa-brands fa-facebook"></i>
              </a>
              <a href="#" className="item">
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </div>
          </div>
          <div className="main-foot-content pad-bottom">
            <div className="item-menu">
              <h2>DANH MỤC SẢN PHẨM</h2>
              <span></span>
              {Danhmucmenu.map((item, index) => (
                <ListMenu
                  key={item.id}
                  title={item.title}
                  link={item.link}
                ></ListMenu>
              ))}
            </div>
            <div className="item-menu">
              <h2>RUSTER VIỆT NAM</h2>
              <span></span>
              <a>Địa chỉ : 136B Phan Đình Phùng, TP.Thái Nguyên</a>
              <a>Hotline : 0869.678.688</a>
            </div>
            <div className="item-menu">
              <h2>TRUNG TÂM BẢO HÀNH</h2>
              <span></span>
              <a>Địa chỉ : Ngọc Hồi - Thanh Trì - Hà Nội</a>
              <a>Hotline : 0869.679.688</a>
            </div>
            <div className="item-menu">
              <div
                class="fb-page"
                data-href="https://www.facebook.com/CloudFaceVN/"
                data-width="300"
                data-height="150"
                data-hide-cover="false"
                data-show-facepile="false"
              ></div>
            </div>
          </div>
        </div>
        <div className="bot-foot-content">
          <p>
            Copyright © 2016 Ruster Việt Nam - thương hiệu sản xuất đầu tiên và
            duy nhất MOTOR CỔNG TỰ ĐỘNG TẠI VIỆT NAM
          </p>
        </div>
      </div>
    </div>
  );
};

export default footer;
