import "./style/Main.css";
import { Danhmucmenu } from "../Data/Danhmucmenu";
import Slider from "./slider";
import ListSanpham from "./CompListSP/ListSanpham";

function NarLeft(props) {
  return (
    <div>
      <div className="narleft">
        <a href={props.link}>{props.title}</a>
        <i class="fa-solid fa-caret-right"></i>
      </div>
    </div>
  );
}

const NarRight = () => {
  return (
    <div>
      <Slider></Slider>
      <div className="bannermin">
        <a href="">
          <i class="fa-solid fa-circle-check"></i>
          <i class="fa-solid fa-door-open"></i>
          <span></span>Tư vấn thiết bị cửa tự động
        </a>
        <a href="">
          <i class="fa-solid fa-circle-check"></i>
          <i class="fa-solid fa-camera-retro"></i>
          <span></span>Linh kiện điện tử - Camera - Wifi
        </a>
        <a href="">
          <i class="fa-solid fa-circle-check"></i>
          <i class="fa-solid fa-business-time"></i>
          <span></span>Tư vấn lắp đặt hạ Tầng CNTT
        </a>
      </div>
    </div>
  );
};

const MainHeader = () => {
  return (
    <section className="container MainHeader">
      <div className="ContentNarletf">
        {Danhmucmenu.map((item, index) => (
          <NarLeft key={item.id} title={item.title} link={item.link}></NarLeft>
        ))}
      </div>
      <div className="NarRigth">
        <NarRight></NarRight>
      </div>

      <ListSanpham></ListSanpham>
    </section>
  );
};

export default MainHeader;
