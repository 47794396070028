export const Danhmucmenu = [
  {
    id: 1,
    title: "Cửa tự động",
    link: "Cloudface.vn"
  },
  {
    id: 2,
    title: "Thiết bị Camera",
    link: "#"
  },
  {
    id: 3,
    title: "Linh kiện điện tử",
    link: "#"
  },
  {
    id: 4,
    title: "Tư vấn lắp đặt Camera",
    link: "#"
  },
  {
    id: 5,
    title: "Tư vấn lắp đặt hạ tầng CNTT",
    link: "#"
  }
]